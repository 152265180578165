<template>

    <div>
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  variant="primary"
                  @click="addNew()"
                >
                  <span class="text-nowrap">Add New Receive</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refPurchaseOrderReceiveWarehouseTable"
          class="position-relative"
          :items="fetchMaterialRequestReceives"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <!-- Column: MaterialRequestNumber -->
          <template #cell(MaterialRequestNumber)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{data.item.material_request.material_request_number }}</span>
            </div>
          </template>
          <!-- Column: TotalReceived -->
          <template #cell(TotalReceived)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{ data.item.material_request_receive_total_received }}</span>
            </div>
          </template>
          <!-- Column: CreatedBy By -->
          <template #cell(CreatedBy)="data">
              {{ data.item.user.user_fullname }}
          </template>
          <!-- Column: DateCreated -->
          <template #cell(DateCreated)="data">
              {{ dateSimple(data.item.material_request_receive_created_time, 'YYYY-MM-DD HH:mm:ss') }}
          </template>
          <!-- Column: Action -->
          <template #cell(Action)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="showDetail( data.item, data.item.material_request.material_request_number)">
                <feather-icon icon="ZoomInIcon" />
                <span class="align-middle ml-50">Detail</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalMaterialRequestReceiveses"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>
  </template>

  <script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BModal, VBModal, BListGroup, BListGroupItem,
  } from 'bootstrap-vue'
  import axios from 'axios'
  import vSelect from 'vue-select'
  import store from '@/store'
  import { getUserData } from '@/auth/utils'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { avatarText } from '@core/utils/filter'
  import moment from 'moment'
  import { VueHorizontalTimeline } from "vue-horizontal-timeline"
  import PurchaseOrderReceiveWarehouse from './ReceiveList'

  const userRole = ''

  const messageBox = ''
  const to = ''
  const cc = ''
  const reason = ''
  const PurchaseOrderId = ''
  const messagesList = []
  const statusItems = []
  const items = []
  const userId = getUserData().user_id
  const modalDetailShow = false
  const modalMessage = false
  export default {
    components: {
      VueHorizontalTimeline,
      BListGroup,
      BListGroupItem,
      // PurchaseOrderesListFilters,
      BModal,
      VBModal,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,

      vSelect,
    },
    data() {
      return {
      items,
      statusItems,
      PurchaseOrderId,
      userId,
      messagesList,
      }
    },
    mounted() {
      this.userRole = this.$cookies.get('UserRole')
      this.$root.$on('refreshTable', text => {
      console.log(text) // here you need to use the arrow function
      this.$refs.refPurchaseOrderReceiveWarehouseTable.refresh()
    })
    },
    setup() {
      const BRANCH_APP_STORE_MODULE_NAME = 'app-purchase-order'
        // UnRegister on leave
        onUnmounted(() => {
          if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
        })

      const isAddNewPurchaseOrderSidebarActive = ref(false)

      const statusOptions = [
        { label: 'Pending', value: 'pending' },
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ]

      const {
        fetchMaterialRequestReceives,
        fetchRoles,
        tableColumns,
        perPage,
        currentPage,
        totalMaterialRequestReceiveses,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPurchaseOrderReceiveWarehouseTable,
        refetchData,

        // UI
        resolvePurchaseOrderRoleVariant,
        resolvePurchaseOrderRoleIcon,
        resolvePurchaseOrderStatusVariant,

        // Extra Filters
        roleFilter,
        statusFilter,
      } = PurchaseOrderReceiveWarehouse()

      const roleOptions = [
        { label: 'Admin', value: 'admin' },
        { label: 'Author', value: 'author' },
        { label: 'Editor', value: 'editor' },
        { label: 'Maintainer', value: 'maintainer' },
        { label: 'Subscriber', value: 'subscriber' },
      ]

      return {
        to,
        cc,
        reason,
        messageBox,
        modalDetailShow,
        modalMessage,
        // Sidebar
        isAddNewPurchaseOrderSidebarActive,
        userRole,
        fetchMaterialRequestReceives,
        fetchRoles,
        tableColumns,
        perPage,
        currentPage,
        totalMaterialRequestReceiveses,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refPurchaseOrderReceiveWarehouseTable,
        refetchData,

        // Filter
        avatarText,

        // UI
        resolvePurchaseOrderRoleVariant,
        resolvePurchaseOrderRoleIcon,
        resolvePurchaseOrderStatusVariant,

        roleOptions,
        statusOptions,

        // Extra Filters
        roleFilter,
        statusFilter,
      }
    },
    methods: {
        deletePurchaseOrder(id) {
          const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
            axios
                .get(`${process.env.VUE_APP_API_DELETE_MATERIAL_REQUEST}${id}`, { headers })
                .then(response => {
                  console.log(response)
                  if (response.data.success === true) {
                    this.$refs.refPurchaseOrderReceiveWarehouseTable.refresh()
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Congratulation',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'Your data has been deleted!',
                      },
                    })
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Something went wrong',
                      },
                    })
                  }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        editPurchaseOrder(data) {
            this.$cookies.set('PurchaseOrderEdit', data)
            this.$router.push({ name: 'apps-purchase-order-edit' })
        },
        confirmPurchaseOrder(id) {
          const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
          const body = {
          time: moment(),
          }
      axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER}/${id}`, body, { headers })
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderReceiveWarehouseTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Confirm Purchase OrderSuccess',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        },
        sendPurchaseOrder(data) {
            this.$cookies.set('PurchaseOrderSend', data)
            this.$router.push({ name: 'apps-purchase-order-send' })
        },
      addNew() {
            this.$router.push({ name: 'apps-material-request-receive-add' })
      },
      dateSimple(value, format = 'YYYY-MM-DD') {
          let dateRet = ''
          if (value !== null) {
            dateRet = moment(String(value)).format(format)
          } else { dateRet = null }
          return dateRet
      },
      showDetail(item, number) {
      localStorage.setItem('ReceiveMaterialRequestDetail', JSON.stringify(item))
      localStorage.setItem('ReceiveMaterialRequestDetailNumber', number)
      this.$router.push({ name: 'apps-material-request-receive-detail' })
      },
      hasPermission(permission) {
        const permissions = this.$cookies.get('userPermissions').split(',')
        return permissions.includes(permission) ? true : false
      },
      async receivePurchasingPurchaseOrder(item) {
        this.$cookies.set('PurchaseOrderDetail', item)
        this.$router.push({ name: 'apps-purchase-order-receive-purchasing' })
      },
      async receiveWarehousePurchaseOrder(item) {
        this.$cookies.set('PurchaseOrderDetail', item)
        this.$router.push({ name: 'apps-purchase-order-receive-warehouse' })
      },
      confirmPurchaseOrderByLabManager(id) {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
          const userData = this.$cookies.get('userData')
          if (userData.user_signature !== null) {
          const body = {
          time: moment(),
          }
      axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/laboratory_manager/${id}`, body, { headers })
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderReceiveWarehouseTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Confirm Purchase OrderSuccess',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'You have to upload your Signature First!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
      confirmPurchaseOrderByRegionalManager(id) {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
          const body = {
          time: moment(),
          }
      axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/regional_manager/${id}`, body, { headers })
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderReceiveWarehouseTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Confirm Purchase OrderSuccess',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
      },
      confirmPurchaseOrderByDivisionHead(id) {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
          const body = {
          time: moment(),
          }
      axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/division_head/${id}`, body, { headers })
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderReceiveWarehouseTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Confirm Purchase OrderSuccess',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
      },
      confirmPurchaseOrderByFACoalManager(id) {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
          const body = {
          time: moment(),
          }
      axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/fa_coal_manager/${id}`, body, { headers })
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderReceiveWarehouseTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Confirm Purchase OrderSuccess',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
      },
      confirmPurchaseOrderByOperationDirector(id) {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
        const userData = this.$cookies.get('userData')
          if (userData.user_signature !== null) {
          const body = {
          time: moment(),
          }
      axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/operation_director/${id}`, body, { headers })
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderReceiveWarehouseTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Confirm Purchase OrderSuccess',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'You have to upload your Signature First!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
      confirmPurchaseOrderByVicePresident(id) {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
        const userData = this.$cookies.get('userData')
          if (userData.user_signature !== null) {
          const body = {
          time: moment(),
          }
      axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/vice_president/${id}`, body, { headers })
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderReceiveWarehouseTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Confirm Purchase OrderSuccess',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'You have to upload your Signature First!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
      confirmPurchaseOrderByPresidentDirector(id) {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
        const userData = this.$cookies.get('userData')
          if (userData.user_signature !== null) {
          const body = {
          time: moment(),
          }
      axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/president_director/${id}`, body, { headers })
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderReceiveWarehouseTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Confirm Purchase OrderSuccess',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'You have to upload your Signature First!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
      confirmPurchaseOrderByProcurementPusat(id) {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
          const body = {
          time: moment(),
          }
      axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER_CENTER}/procurement_pusat/${id}`, body, { headers })
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderReceiveWarehouseTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Confirm Purchase OrderSuccess',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
      },
      async sendMessage() {
        const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
        const id = this.PurchaseOrderId
        const Message = this.messageBox
        const body = {
          purchase_order_chat_purchase_order_id: id,
          purchase_order_chat_text: Message,
          time: moment(),
        }
      axios
        .post(`${process.env.VUE_APP_API_CREATE_MATERIAL_REQUEST_CHATS}`, body, { headers })
        .then(async response => {
          if (response.data.success === true) {
            this.messageBox = ''
      axios
        .get(`${process.env.VUE_APP_API_GET_MATERIAL_REQUEST_CHATS}${id}`, { headers })
        .then(response2 => {
          if (response2.data.success === true) {
            console.log('Fetching Data')
        if (response2.data.data) {
        messagesList.splice(0, messagesList.length)
          response2.data.data.map(value => {
            messagesList.push(value)
            return value.invoiceMessage
          })
        }
          }
          })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
      },
    },
  }
  </script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
